module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"glide social","short_name":"glide","lang":"en","description":"glide social: news, local real connections & more","start_url":"/","background_color":"#5abbff","theme_color":"#5abbff","dir":"ltr","display":"fullscreen","orientation":"any","icon":"src/images/favicon.png","icons":[{"src":"/android-icon-192x192-seochecker-manifest-4212.jpg","type":"image/png","sizes":"192x192"},{"src":"/apple-icon-180x180-seochecker-manifest-4212.jpg","type":"image/png","sizes":"180x180"},{"src":"/apple-icon-152x152-seochecker-manifest-4212.jpg","type":"image/png","sizes":"152x152"},{"src":"/apple-icon-144x144-seochecker-manifest-4212.jpg","type":"image/png","sizes":"144x144"},{"src":"/apple-icon-120x120-seochecker-manifest-4212.jpg","type":"image/png","sizes":"120x120"},{"src":"/apple-icon-114x114-seochecker-manifest-4212.jpg","type":"image/png","sizes":"114x114"},{"src":"/favicon-96x96-seochecker-manifest-4212.jpg","type":"image/png","sizes":"96x96"},{"src":"/apple-icon-76x76-seochecker-manifest-4212.jpg","type":"image/png","sizes":"76x76"},{"src":"/apple-icon-72x72-seochecker-manifest-4212.jpg","type":"image/png","sizes":"72x72"},{"src":"/apple-icon-60x60-seochecker-manifest-4212.jpg","type":"image/png","sizes":"60x60"},{"src":"/apple-icon-57x57-seochecker-manifest-4212.jpg","type":"image/png","sizes":"57x57"},{"src":"/favicon-32x32-seochecker-manifest-4212.jpg","type":"image/png","sizes":"32x32"},{"src":"/favicon-16x16-seochecker-manifest-4212.jpg","type":"image/png","sizes":"16x16"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d1a058b23d3f043360a82a361578f90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TW3NCZM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
