exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-add-index-js": () => import("./../../../src/pages/contacts/add/index.js" /* webpackChunkName: "component---src-pages-contacts-add-index-js" */),
  "component---src-pages-contacts-detail-[id]-js": () => import("./../../../src/pages/contacts/detail/[id].js" /* webpackChunkName: "component---src-pages-contacts-detail-[id]-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-user-js": () => import("./../../../src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */)
}

